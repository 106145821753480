<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        <v-toolbar-title>
          <span
            style="cursor:pointer;"
            @click="$router.push({ name: 'eKYCAlliance' })"
          >{{ $t('ekyc.listing.title') }}</span>
          <v-icon>mdi-chevron-right</v-icon>
          <span class="subtitle-1 grey--text text--darken-0">{{ $t('ekyc.new.title') }}</span>
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <v-stepper
        v-model="step"
        flat
      >
        <v-stepper-header flat>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            {{ $t('ekyc.new.steps.company.label') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 2"
            step="2"
          >
            {{ $t('ekyc.new.steps.file.label') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">
            {{ $t('ekyc.new.steps.bank.label') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items flat>
          <v-stepper-content
            step="1"
            flat
          >
            <v-row>
              <v-col cols="3">
                Client number
              </v-col>
              <v-col
                cols="auto"
                class="primary--text"
              >
                {{ step1.company ? step1.company.id : '' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                Company Name
              </v-col>
              <v-col
                cols="auto"
                class="primary--text"
              >
                {{ step1.company | company_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                COI Number
              </v-col>
              <v-col
                cols="auto"
                class="primary--text"
              >
                {{ step1.company ? step1.company.coi_number : '' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                BR Number
              </v-col>
              <v-col
                cols="auto"
                class="primary--text"
              >
                {{ step1.company ? step1.company.br_number : '' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-dialog max-width="1200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="step1.loading"
                    >
                      Select
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <loading
                        :active="step1.filterTimeout != null"
                        :is-full-page="false"
                      />
                      <v-card-title>
                        Select Company
                        <v-spacer />
                        <v-btn
                          class="mr-3"
                          text
                          @click="dialog.value = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="primary"
                          :disabled="step1.selecting == null"
                          @click="step1.company = step1.list_of_company.find(x => x.id == step1.selecting); dialog.value = false"
                        >
                          Apply
                        </v-btn>
                      </v-card-title>
                      <!-- <v-card-actions class="justify-end">
                                                <v-btn text @click="dialog.value = false">Close</v-btn>
                                            </v-card-actions> -->
                      <!-- <v-toolbar color="primary" dark >Opening from the bottom</v-toolbar> -->
                      <v-card-text>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="text-left"
                                  width="64px;"
                                />
                                <th class="text-left">
                                  Client Number
                                  <v-text-field
                                    dense
                                    placeholder="Filter"
                                    v-model="step1.filter.id"
                                  />
                                </th>
                                <th class="text-left">
                                  COI Number
                                  <v-text-field
                                    dense
                                    placeholder="Filter"
                                    v-model="step1.filter.coi_number"
                                  />
                                </th>
                                <th class="text-left">
                                  Company Name
                                  <v-text-field
                                    dense
                                    placeholder="Filter"
                                    v-model="step1.filter.name"
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="step1.filterTimeout != null">
                                <td
                                  colspan="4"
                                  class="text-center"
                                >
                                  Loading...
                                </td>
                              </tr>
                              <template v-else-if="step1.list_of_company.length > 0">
                                <tr
                                  v-for="(company, key) in step1.list_of_company"
                                  :key="key"
                                >
                                  <td class="text-left">
                                    <v-checkbox
                                      :value="company.id"
                                      :multiple="false"
                                      v-model="step1.selecting"
                                      hide-details
                                      class="mt-0"
                                    />
                                  </td>
                                  <td>{{ company.id }}</td>
                                  <td>{{ company.coi_number }}</td>
                                  <td>{{ company | company_name }}</td>
                                </tr>
                              </template>
                              <tr v-else>
                                <td
                                  colspan="4"
                                  class="text-center"
                                >
                                  {{ (step1.filter.id || step1.filter.coi_number || step1.filter.name) ? 'No record match' : 'Type to search' }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn
                  text
                  :to="{ name: 'eKYCAlliance' }"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  @click="onStep1Complete"
                  :loading="step1.loading"
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <eKycAllianceDocument
              v-if="step2.ekyc_client_info && step >= 2"
              :ekyc-client-id="step2.ekyc_client_info.id"
            />
            <v-row>
              <v-col cols="auto">
                <v-btn
                  text
                  @click="step = 1"
                >
                  Back
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  @click="step = 3"
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row>
              <v-col>
                <v-client-table
                  :data="list_of_request"
                  :columns="['banks', 'message', 'action']"
                  :options="{
                    columnsClasses: {
                      banks: '',
                      message: '',
                      action: '',
                    },
                    sortable: [
                      'banks',
                    ],
                    filterable: false,
                    headings: {
                      banks: 'Bank',
                      action: $t('listing.table.action.label'),
                    },
                    texts: {
                      count: $t('listing.table.texts.count'),
                      first: $t('listing.table.texts.first'),
                      last: $t('listing.table.texts.last'),
                      filter: $t('listing.table.texts.filter'),
                      filterPlaceholder: $t('listing.table.texts.filterPlaceholder'),
                      limit: $t('listing.table.texts.limit'),
                      page: $t('listing.table.texts.page'),
                      noResults: $t('listing.table.texts.noResults'),
                      noRequest: $t('listing.table.texts.noRequest'),
                      filterBy: $t('listing.table.texts.filterBy'),
                      loading: $t('listing.table.texts.loading'),
                      defaultOption: $t('listing.table.texts.defaultOption'),
                      columns: $t('listing.table.texts.columns'),
                    },
                  }"
                >
                  <div slot="afterFilterWrapper">
                    <v-dialog
                      max-width="1280px"
                      v-model="dialog_create_request"
                      persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :loading="requesting"
                        >
                          <v-icon left>
                            mdi-plus
                          </v-icon>
                          Create Request
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title>
                            {{ request_editing ? 'Update request' : 'Create new request' }}
                            <v-spacer />
                            <v-btn
                              class="mr-3"
                              text
                              @click="dialog.value = false;resetCreateRequestDialog();"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              @click="onConfirmRequest"
                              color="primary"
                              :loading="requesting"
                            >
                              {{ request_editing ? 'Update' : 'Confirm' }}
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="6">
                                <v-autocomplete
                                  :label="request_bank ? 'Bank' : ''"
                                  placeholder="Start typing to search Banks"
                                  prepend-inner-icon="mdi-database-search"
                                  clearable
                                  v-model="request_bank"
                                  :items="list_of_bank.map(bank => ({
                                    text: $options.filters.x500_name(bank, 'O'),
                                    value: bank
                                  }))"
                                  :loading="searching"
                                  :search-input.sync="search"
                                  hide-no-data
                                  hide-selected
                                  :error="!$v.request_bank.required"
                                  :error-messages="!$v.request_bank.required ? $t('validate.required', { field: 'Bank' }) : ''"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-textarea
                                  :label="request_message ? 'Message' : ''"
                                  placeholder="Any message to bank"
                                  v-model="request_message"
                                  auto-grow
                                  rows="1"
                                  row-height="25"
                                />
                              </v-col>
                            </v-row>
                            <div>{{ request_files.length }} file(s) selected.</div>
                            <div
                              v-if="!$v.request_files.required"
                              class="error--text"
                            >
                              {{ $t('validate.required', { field: 'Files' }) }}
                            </div>
                            <e-kyc-alliance-document
                              :table-name="'ekyc/documentSelectTable'"
                              v-if="step2.ekyc_client_info.id && dialog_create_request"
                              :ekyc-client-id="step2.ekyc_client_info.id"
                              mode="selecting"
                              :selected-items="request_files"
                              @selected="(files) => { request_files = files }"
                            />
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-dialog>
                  </div>
                  <div
                    slot="banks"
                    slot-scope="props"
                  >
                    {{ props.row.banks[0] | x500_name('O') }}
                  </div>
                  <div
                    slot="action"
                    slot-scope="props"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="25"
                      :width="2"
                      v-if="props.row.loading"
                    />
                    <span
                      v-else-if="props.row.failed"
                      class="error--text"
                    >
                      <v-icon color="error">mdi-alert-circle</v-icon>
                      Failed to submit
                    </span>
                    <span
                      v-else-if="props.row.completed"
                      class="success--text"
                    >
                      <v-icon color="success">mdi-check-circle</v-icon>
                      Submitted
                    </span>
                    <v-btn
                      icon
                      class="mr-1"
                      :disabled="props.row.completed || props.row.loading"
                      @click="() => {
                        request_editing = props.row.uuid;
                        request_bank = props.row.banks[0];
                        request_files = props.row.documents.map(doc => doc.id)
                        request_message = props.row.message
                        dialog_create_request = true;
                      }"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      class="mr-1"
                      color="error"
                      :disabled="props.row.completed || props.row.loading"
                      @click="() => {
                        var index = list_of_request.findIndex(request => request.uuid == props.row.uuid)
                        if (index != -1)
                          list_of_request.splice(index, 1);
                      }"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-client-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto">
                <v-btn
                  text
                  @click="step = 2"
                >
                  Back
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <!-- <v-btn color="primary" :to="{ name: 'eKYCAlliance' }">
                                    Submit Requests
                                </v-btn> -->
                <v-btn
                  color="primary"
                  @click="onSubmit"
                  :loading="requesting"
                >
                  Submit Requests
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <!-- <v-container fill-height fluid>

    </v-container> -->
  </v-card>
</template>

<script>
import uuidv4 from 'uuid/v4'
// import { mapGetters, mapState } from 'vuex'
// import DateFormat from '@/components/common/DateFormat'
import eKycAllianceDocument from '@/components/forms/eKycAllianceDocument'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

Vue.use(Vuelidate)

export default {
  components: {
    eKycAllianceDocument
  },
  watch: {
    'step1.filter': {
      deep: true,
      handler () {
        this.search_company()
      }
    },
    search (unusedVal) {
      // Items have already been loaded
      if (this.list_of_bank.length > 0) return

      // Items have already been requested
      if (this.searching) return

      this.searching = true

      this.$store.dispatch('ekyc/getBankList')
        .then((results) => {
          if (results.data) {
            this.list_of_bank = results.data
          }
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.searching = false
        })
    }
  },
  data () {
    return {
      step: 1,
      step1: {
        loading: false,
        company: null,
        filter: {
          id: '',
          coi_number: '',
          name: ''
        },
        filterTimeout: null,
        list_of_company: [],
        selecting: null
      },
      step2: {
        ekyc_client_info: null
        // selectedFile: null,
        // isSelecting: false,
        // uploading: false,
        // files: []
      },
      step3: {
        // loading: false,
        // list_of_bank: [],
        // bank_to_request: null,
        // dialog_request: false,
        // file_to_share: []
      },
      // dialog_delete_confirmation: false,
      // dialog_delete_confirmation_loading: false,
      // dialog_delete_confirmation_record_id: null,
      list_of_request: [],
      dialog_create_request: false,
      list_of_bank: [],
      // autocomplete
      search: '',
      searching: false,
      // request creation
      requesting: false,
      request_files: [],
      request_message: '',
      request_bank: null,
      request_editing: null
    }
  },
  validations () {
    return {
      request_bank: {
        required
      },
      request_files: {
        required
      }
    }
  },
  computed: {
    // ...mapGetters('attachment', [
    //     'options'
    // ]),
    // ...mapState('attachment', {
    // 	requestLoading: state => state.listing.requestLoading,
    // 	columns: state => {
    //         return [
    //             'select',
    //             'file_name',
    //             'description',
    //             'create_date',
    //             'sync',
    //             'file_mime',
    //             'action',
    //         ];
    //         // return state.listing.columns
    //     },
    // })
  },
  methods: {
    async search_company () {
      clearTimeout(this.step1.filterTimeout)
      this.step1.filterTimeout = setTimeout(async () => {
        try {
          var name_match = this.step1.filter.name ? await this.$client.name.findWhere({
            where: {
              other: { contains: this.step1.filter.name },
              company: { '>': 0 }
            },
            select: 'company',
            populate: 'person'
          }) : { data: [] }
          var company_ids = [this.step1.filter.id, ...name_match.data.map(x => x.company)].filter(Boolean)
          var company = await this.$client.corporate.findWhere({
            where: company_ids.length || this.step1.filter.coi_number ? {
              or: [
                company_ids.length ? { id: { in: company_ids } } : null,
                this.step1.filter.coi_number ? { coi_number: { contains: this.step1.filter.coi_number } } : null
              ].filter(Boolean)
            } : {},
            populate: 'names'
          })

          if (company.status === 200 && company.data) {
            this.step1.list_of_company = company.data
          }
          this.step1.filterTimeout = null
        } catch (unusedError) {
          this.step1.filterTimeout = null
        }
      }, 800)
    },
    onStep1Complete () {
      if (!this.step1.company) {
        return this.$notify({
          title: 'Creating eKYC Alliance Client',
          text: 'Please select a company',
          type: 'error'
        })
      }
      this.step1.loading = true
      this.$client.ekyc.client.create(this.step1.company.id)
        .then((results) => {
          if (results.status === 200 && results.data) {
            this.step2.ekyc_client_info = results.data
            this.step = 2
          }
        })
        .catch((error) => {
          this.$notify({
            title: 'Create eKYC Alliance Profile',
            text: error.response.data.errorMessage || 'Exception',
            type: 'error'
          })
        })
        .finally(() => {
          this.step1.loading = false
        })
    },
    resetCreateRequestDialog () {
      this.request_editing = null
      this.request_bank = null
      this.request_message = ''
      this.request_files.splice(0)
    },
    onConfirmRequest () {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }
      this.dialog_create_request = false
      if (this.request_editing) {
        var index = this.list_of_request.findIndex(request => request.uuid === this.request_editing)
        if (index !== -1) {
          this.list_of_request[index].banks = [this.request_bank]
          this.list_of_request[index].message = this.request_message
          this.list_of_request[index].documents = this.request_files.map(ekyc_document_id => {
            return {
              externalId: '',
              id: ekyc_document_id
            }
          })
        }
      } else {
        this.list_of_request.push({
          uuid: uuidv4(),
          ekyc_client_id: this.step2.ekyc_client_info.id,
          banks: [this.request_bank],
          message: this.request_message,
          documents: this.request_files.map(ekyc_document_id => {
            return {
              externalId: '',
              id: ekyc_document_id
            }
          }),
          // status
          loading: false,
          completed: false,
          failed: false
        })
      }

      // Clear form
      return this.resetCreateRequestDialog()
    },
    onSubmit () {
      this.requesting = true
      Promise.all(this.list_of_request
        .filter(request => request.completed === false)
        .map((request) => {
          return new Promise((resolve, reject) => {
            request.loading = true
            request.completed = false // reset
            request.failed = false // reset
            // return setTimeout(() => {
            // 	request.loading = false;
            // 	var a = Math.random(),
            // 		tmp = a < 0.2
            // 	console.log(request, a, tmp)
            // 	if (tmp) {
            // 		request.failed = true;
            // 		return reject();
            // 	}
            // 	request.completed = true;
            // 	resolve();
            // }, 3000)
            this.$client.ekyc.request.create(request.ekyc_client_id, request.banks, request.documents, request.message)
              .then((results) => {
                request.completed = true
                resolve(results)
              })
              .catch(() => {
                request.failed = true
                reject()
              })
              .finally(() => {
                request.loading = false
              })
          })
        }))
        .then((unusedResults) => {
          // all request submitted
          this.$notify({
            title: 'Request Submission',
            text: 'All request(s) submitted',
            type: 'success'
          })
          this.$router.push({ name: 'eKYCAlliance' })
        })
        .catch(() => {
          this.$notify({
            title: 'Request Submission',
            text: 'One or more request(s) failed to submit',
            type: 'error'
          })
        })
        .finally(() => {
          this.requesting = false
        })
    }
  },
  mounted () {
    this.search_company()
    console.log(this)
  }
}
</script>

<style>

</style>
