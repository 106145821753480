<template>
  <div>
    <loading
      :active.sync="listing_document_loading"
      :is-full-page="false"
    />
    <v-server-table
      v-if="ekycClientId"
      class="pt-0"
      ref="table"
      :name="tableName"
      :columns="listing_document_columns"
      :options="listing_document_options(ekycClientId)"
    >
      <div
        slot="afterFilterWrapper"
        v-if="mode == 'editing'"
      >
        <v-btn
          color="primary"
          class="mr-3"
          outlined
          @click="onUploadClick"
          :loading="file_selecting || uploading"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Upload New File
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          accept="*/*"
          @change="onFileChanged"
          v-if="file_selecting"
        >
        <v-btn
          color="success"
          outlined
          @click="onSelectExistingClick"
          :disabled="!companyId"
        >
          <v-icon left>
            mdi-folder-plus-outline
          </v-icon>
          Select Existing
        </v-btn>
        <!-- <v-btn color="success" outlined @click="verify_selected_files">
                    <v-icon left>mdi-sync</v-icon>
                    Sync Files
                </v-btn> -->
        <v-dialog
          v-model="dialog_upload"
          width="500"
        >
          <v-card :loading="uploading">
            <v-card-title>
              Upload File
              <v-spacer />
              <v-btn
                @click="onUpload"
                outlined
                color="primary"
                :loading="uploading"
              >
                <v-icon left>
                  mdi-upload
                </v-icon>
                Upload
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  File
                </v-col>
                <v-col
                  cols="auto"
                  class="primary--text"
                >
                  {{ file_selected ? file_selected.name : '' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Descritpion"
                    v-model="file_description"
                    auto-grow
                    rows="1"
                    row-height="15"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialog_existing"
          max-width="1280"
          v-if="companyId"
        >
          <v-card :loading="listing_existing_loading">
            <v-card-title>
              Select Existing Document
              <v-spacer />
              <v-btn
                @click="dialog_existing = false"
                text
                class="mr-3"
              >
                Cancel
              </v-btn>
              <v-btn
                @click="onUploadExisting"
                color="primary"
                :loading="uploading_existing"
              >
                <!-- <v-icon left>mdi-upload</v-icon> -->
                Add ({{ selected_items_existing.length }}) to Profile
              </v-btn>
            </v-card-title>
            <v-card-text>
              <Attachment
                v-if="dialog_existing"
                :parent-id="companyId"
                parent-type="company"
                mode="selecting"
                :unselectable="(item) => { return item.ekyc_document_hash != '' ? 'File already in blockchain network' : false }"
                @selected="onSelectExisting"
                :selected-items="selected_items_existing"
              />
              <!-- <v-server-table
                                class="pt-0" ref="tableExisting" :name="'attachment/attachmentTable'"
                                :columns="listing_existing_columns"
                                :options="listing_existing_options({ company_id: company_id })">
                                <div slot="create_date" slot-scope="props">
                                    <DateFormat :date="props.row.create_date" :showtime="true"/>
                                </div>
                            </v-server-table> -->
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div
        slot="name"
        slot-scope="props"
      >
        <v-checkbox
          v-if="mode == 'selecting'"
          v-model="selected_items_local"
          multiple
          @change="onSelect"
          :value="props.row.id"
          :label="props.row.name"
        />
        <span v-else>{{ props.row.name }}</span>
      </div>
      <div
        slot="uploadedAt"
        slot-scope="props"
      >
        <DateFormat
          :date="props.row.uploadedAt"
          :showtime="true"
        />
      </div>
      <div
        slot="action"
        slot-scope="props"
      >
        <eKycAllianceDocumentAction :ekyc-document="props.row" />
      </div>
      <div
        slot="share"
        slot-scope="props"
      >
        <v-dialog
          max-width="350"
          v-model="dialog_share"
          v-if="sharedItems.findIndex(doc => doc.id == props.row.id) == -1"
        >
          <template v-slot:activator="{ on: on_dialog, attrs: attrs_dialog }">
            <v-tooltip top>
              <template v-slot:activator="{ on: on_tooltip, attrs: attrs_tooltip }">
                <v-btn
                  icon
                  v-bind="{ ...attrs_tooltip, ...attrs_dialog }"
                  v-on="{ ...on_dialog, ...on_tooltip }"
                >
                  <v-icon>mdi-share</v-icon>
                </v-btn>
              </template>
              <span>Share file with bank</span>
            </v-tooltip>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title>Share Confirmation</v-card-title>
              <v-card-text>Your file is already in the blockchain network. <br>Are you sure to share this file with bank?</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  :loading="file_sharing"
                  @click="onShareFile(props.row.id)"
                >
                  Yes, Share
                </v-btn>
                <v-btn
                  text
                  color="darken-1"
                  @click="dialog.value = false"
                >
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog
          max-width="350"
          v-model="dialog_unshare"
          v-else
        >
          <template v-slot:activator="{ on: on_dialog, attrs: attrs_dialog }">
            <v-tooltip top>
              <template v-slot:activator="{ on: on_tooltip, attrs: attrs_tooltip }">
                <v-btn
                  icon
                  color="error"
                  v-bind="{ ...attrs_dialog, ...attrs_tooltip }"
                  v-on="{ ...on_dialog, ...on_tooltip }"
                >
                  <v-icon>mdi-share-off</v-icon>
                </v-btn>
              </template>
              <span>Unshare file with bank</span>
            </v-tooltip>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title>Unshare Confirmation</v-card-title>
              <v-card-text>Your file has been shared with bank. <br>Are you sure to unshare this file?</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  color="error"
                  :loading="file_unsharing"
                  @click="onUnshareFile(props.row.id)"
                >
                  Yes, Unshare
                </v-btn>
                <v-btn
                  text
                  color="darken-1"
                  @click="dialog.value = false"
                >
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </v-server-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import eKycAllianceDocumentAction from '@/components/forms/eKycAllianceDocumentAction'
import Attachment from '@/components/forms/Attachment'

export default {
  props: {
    tableName: {
      default: 'ekyc/documentTable',
      type: String
    },
    ekycClientId: {
      default: null,
      type: String
    },
    mode: {
      type: String,
      default: 'editing',
      validator: function (value) {
        // The value must match one of these strings
        return ['selecting', 'editing', 'sharing'].indexOf(value) !== -1
      }
    },
    ekycRequestRef: {
      default: null,
      type: String // only for "sharing" mode
    },
    sharedItems: {
      type: Array,
      default: () => ([])
    },
    selectedItems: {
      type: Array,
      default: () => ([])
    },
    companyId: { // only for editing "Select Existing" button
      default: null,
      type: [String, Number]
    }
  },
  components: {
    DateFormat,
    eKycAllianceDocumentAction,
    Attachment
  },
  watch: {
    ekycClientId () {
      if (this.$refs.table) { this.$refs.table.getData() }
    }
  },
  computed: {
    ...mapState('ekyc', {
      listing_document_loading: state => state.listing_document.requestLoading,
      listing_document_columns (state) {
        // return state.listing_document.columns;
        // console.log(state.listing_document.columns.concat(this.mode == 'sharing' ? ['share'] : []))
        return state.listing_document.columns.concat(this.mode === 'sharing' ? ['share'] : [])
      }
    }),
    ...mapGetters('ekyc', {
      listing_document_options: 'listing_document_options'
    }),
    ...mapState('attachment', {
      listing_existing_loading: state => state.listing.requestLoading,
      listing_existing_columns: state => state.listing.columns
    }),
    ...mapGetters('attachment', {
      listing_existing_options: 'options'
    })
  },
  data () {
    return {
      selected_items_local: [],
      file_selected: null,
      file_description: '',
      file_selecting: false,
      uploading: false,
      dialog_upload: false,
      // file share
      file_sharing: false,
      file_unsharing: false,
      dialog_share: false,
      dialog_unshare: false,
      // table
      // requestTimeout: null,
      // requestLoading: false,
      // columns: [/*'select', */'name', 'description', 'uploadedAt', 'mimeType', 'action'],
      // options: {
      //     initFilters: [],
      //     initialPage: 1,
      //     perPage: 10,
      //     orderBy: {
      //         ascending: true,
      //         column: null,
      //     },
      //     columnsClasses: {
      //         // name: 'col-2',
      //         // description: 'col-2',
      //         // uploadedAt: 'col-2',
      //         // mimeType: 'col-1',
      //         // action: 'col-1'
      //     },
      //     sortable: [],
      //     // filterable: ['bank_name', 'status', 'request_date', 'message', 'action'],
      //     filterByColumn: true,
      //     // dateColumns: ['request_date'],
      //     // dateFormat: 'DD/MMM/YYYY',
      // },
      // existing doc
      dialog_existing: false,
      selected_items_existing: [],
      uploading_existing: false
    }
  },
  methods: {
    refresh () {
      this.$refs.table.getData()
    },
    onSelect (selected) {
      this.$emit('selected', selected)
      // console.log(arguments);
    },
    onFileChanged (e) {
      this.file_selected = e.target.files[0]
      console.log(this.file_selected)
      // upload
      if (this.file_selected) {
        this.dialog_upload = true
      }
    },
    onUploadClick () {
      this.file_selecting = true
      this.$nextTick(() => {
        this.file_selected = null
        window.addEventListener('focus', () => { this.file_selecting = false }, { once: true })
        this.$refs.uploader.click()
      })
    },
    onUpload () {
      this.uploading = true
      this.$client.ekyc.document.upload(this.ekycClientId, this.file_description, this.file_selected)
        .then((results) => {
          if (results.status === 200 && results.data) {
            this.file_selected = null
            this.file_description = ''

            if (this.$refs.table) { this.$refs.table.getData() }

            return this.$notify({
              type: 'success',
              title: 'File Upload',
              text: 'The file uploaded successfully'
            })
          } else {
            this.$notify({
              type: 'error',
              title: 'File Upload',
              text: 'Failed to upload file'
            })
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response)
            this.$notify({
              type: 'error',
              title: 'File Upload',
              text: error.response.data.message || error.message
            })
          } else {
            this.$notify({
              type: 'error',
              title: 'File Upload',
              text: error.message
            })
          }
        })
        .finally(() => {
          this.file_selected = null
          this.file_description = ''
          this.uploading = false
          this.dialog_upload = false
        })
    },
    onShareFile (ekycDocumentId) {
      if (!this.ekycRequestRef) { return }
      this.file_sharing = true
      this.$client.request.update(this.ekycRequestRef, [{ id: ekycDocumentId }], [], false)
        .then((results) => {
          if (results.data) {
          // this.$refs.dialog_share.value = false; // close dialog
            this.dialog_share = false
            this.$emit('shared', {
              ekyc_document_id: ekycDocumentId,
              request_info: results.data
            })
          }
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.file_sharing = false
        })
    },
    onUnshareFile (ekycDocumentId) {
      if (!this.ekycRequestRef) { return }
      this.file_unsharing = true
      this.$client.request.update(this.ekycRequestRef, [], [{ id: ekycDocumentId }], false)
        .then((results) => {
          if (results.data) {
            // this.$refs.dialog_unshare.value = false; // close dialog
            this.dialog_unshare = false
            this.$emit('unshared', {
              ekyc_document_id: ekycDocumentId,
              request_info: results.data
            })
          }
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.file_unsharing = false
        })
    },
    onSelectExistingClick () {
      this.dialog_existing = true
    },
    onSelectExisting (selected) {
      this.selected_items_existing = selected
    },
    onUploadExisting () {
      // this.dialog_existing = false;
      this.uploading_existing = true
      this.$client.ekyc.document.uploadExist(this.companyId, this.selected_items_existing)
        .then((results) => {
          this.selected_items_existing.splice(0)
          if (results.data.failed && results.data.failed.length) {
            this.$notify({
              title: 'Upload Existing Files',
              text: `One or more file(s) failed to upload (failed: ${results.data.failed.length}, completed: ${results.data.completed.length})`,
              type: 'error'
            })
          } else {
            this.$notify({
              title: 'Upload Existing Files',
              text: 'All file(s) to uploaded',
              type: 'success'
            })
          }
          if (this.$refs.table) { this.$refs.table.getData() }
        })
        .catch((unusedError) => {

        })
        .finally(() => {
          this.uploading_existing = false
          this.dialog_existing = false
        })
    }
  },
  mounted () {
    // console.log(this);
    this.selected_items_local = this.selectedItems
  }
}
</script>

<style>

</style>
