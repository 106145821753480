var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":25,"width":2}}):[(_vm.verifying)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":25,"width":2}}):[(_vm.verify_results && _vm.verify_results.contentHashMatch)?_c('v-tooltip',{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-check")])],1)]}}],null,false,2817855321)},[_c('span',[_vm._v("File Synced")])]):_c('v-tooltip',{attrs:{"top":"","color":"error","max-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.find_attachment(_vm.ekycDocument)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.loading_error ? 'mdi-file-alert' : ( _vm.verifying_error ? 'mdi-shield-alert' : 'mdi-file-hidden' )))])],1)]}}])},[_c('span',{staticStyle:{"word-break":"break-all"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-alert-circle")]),_vm._v(" "+_vm._s(_vm.loading_error ? 'An error occurred' : (( _vm.verifying_error ? (_vm.verifying_error.errorMessage || 'Verify Exception') : 'File not found (RBA)'))))],1)])],(_vm.rba_document)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":_vm.view_document}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,4136342457)},[_c('span',[_vm._v("Preview")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""},on:{"click":_vm.download_document}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,2404445453)},[_c('span',[_vm._v("Download")])])],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }