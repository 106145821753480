<template>
  <span>
    <v-progress-circular
      indeterminate
      :size="25"
      :width="2"
      v-if="loading"
    />
    <template v-else>
      <v-progress-circular
        indeterminate
        :size="25"
        :width="2"
        v-if="verifying"
      />
      <template v-else>
        <v-tooltip
          top
          v-if="verify_results && verify_results.contentHashMatch"
          color="success"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="success"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-shield-check</v-icon>
            </v-btn>
          </template>
          <span>File Synced</span>
        </v-tooltip>
        <v-tooltip
          top
          v-else
          color="error"
          max-width="280px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="find_attachment(ekycDocument)"
            >
              <v-icon>{{ loading_error ? 'mdi-file-alert' : ( verifying_error ? 'mdi-shield-alert' : 'mdi-file-hidden' ) }}</v-icon>
            </v-btn>
          </template>
          <span style="word-break: break-all;"><v-icon dark>mdi-alert-circle</v-icon> {{ loading_error ? 'An error occurred' : (( verifying_error ? (verifying_error.errorMessage || 'Verify Exception') : 'File not found (RBA)')) }}</span>
        </v-tooltip>
      </template>
      <span v-if="rba_document">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="view_document"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Preview</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="download_document"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download</span>
        </v-tooltip>
      </span>
      <!-- <span v-else class="error--text">
                {{ loading_error ? 'An error occurred' : 'Not found' }}
            </span> -->
    </template>
  </span>
</template>

<script>
export default {
  props: {
    ekycDocument: {
      default: null,
      type: Object
    }
  },
  watch: {
    ekycDocument (newEkycDocument) {
      this.find_attachment(newEkycDocument)
    }
  },
  data () {
    return {
      loading: false,
      loading_error: false,
      verifying: false,
      verifying_error: false,
      rba_document: null,
      verify_results: null
    }
  },
  methods: {
    find_attachment (ekycDocument) {
      this.loading = true
      this.loading_error = false
      this.$client.attachment.findWhere({
        where: {
          ekyc_document_hash: ekycDocument.hash,
          ekyc_document_id: ekycDocument.id
        }
      })
        .then((results) => {
          if (results.data.length > 0) {
            this.rba_document = results.data[0]
            if (this.rba_document) {
              this.verify_attachment()
            }
          }
          // console.log(results);
        })
        .catch(() => {
          this.loading_error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    verify_attachment () {
      this.verifying = true
      this.verifying_error = false
      this.$client.ekyc.document.verify(this.ekycDocument.clientId, this.ekycDocument.id)
        .then((results) => {
          this.verify_results = results.data
        })
        .catch((error) => {
          this.verifying_error = error.response.data
        })
        .finally(() => {
          this.verifying = false
        })
    },
    view_document () {
      if (this.rba_document) {
        window.open(this.rba_document.view_path, '_blank')
      }
    },
    download_document () {
      if (this.rba_document) {
        window.open(this.rba_document.download_path, '_blank')
      }
    }
  },
  mounted () {
    this.find_attachment(this.ekycDocument)
  }
}
</script>

<style>

</style>
